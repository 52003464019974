<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Activités") }}<v-chip outlined class="ml-2">{{ totalItems }}</v-chip>
      </v-card-title>
      <v-divider class="mt-4"></v-divider>


      <!-- table -->
      <v-data-table
        class="fixed-table"
        fixed-header
        height="500"
        dense
        :headers="filters"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        hide-default-footer
        :show-select="false"
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters" />
        </template>
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-btn
            icon
            small
          >
            <v-icon
              size="20"
              @click="openDialog(item)"
            >
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            color="error"
          >
            <v-icon
              size="20"
              color="error"
              @click="deleteItem(item)"
            >
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiDotsVertical,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// sidebar
import { formatDate, formatDateToMonthShort } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import router from '@/router'
import RowsPerPage from '@/components/RowsPerPage'
import HeaderFilters from '@/components/HeaderFilters'

export default {
  components: {
    HeaderFilters,
    RowsPerPage,
  },
  props: {
    user: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const activities = ref('')
    const items = ref([])
    const userId = ref(null)
    const loadingActivities = ref(true)
    const isSnackbarBottomVisible = ref(false)
    const snackBarMessage = ref('')
    const isFormActive = ref(false)
    const searchQuery = ref('')
    const roleFilter = ref(null)
    const supervisorFilter = ref(null)
    const statusFilter = ref(null)
    const totalItems = ref(0)
    const loading = ref(false)
    const filters = ref([
      {
        text: 'ID',
        value: 'id',
        width: '70px',
        filter: {
          place_holder: '', type: 'text',
        },
      },
      {
        text: 'Utilisateur',
        value: 'user.name',
        width: '200px',
        filter: {
          place_holder: 'Nom ou email...', type: 'text', columns: ['users.name', 'users.email', 'users.code'], join_table: 'users', join_column: 'users.id', local_column: 'activities.user_id',
        },
      },
      {
        text: 'ID de l\'objet',
        value: 'actionable_id',
        width: '70px',
        filter: {
          place_holder: '', type: 'text',
        },
      },
      {
        text: 'Action',
        value: 'action',
        filter: {
          place_holder: '', type: 'text',
        },
      },

      /* { text: 'Type', value: 'actionable_type' }, */
      {
        text: 'Type',
        value: 'actionable_type',
        filter: {
          place_holder: '',
          key: 'actionable_type',
          text: 'Type',
          type: 'select',
          multiple: true,
          values: [],
          id: 'id',
          label: 'label',
          column: 'actionable_type',
        },
      },
      {
        text: 'Description',
        value: 'description',
        filter: {
          place_holder: '', type: 'text',
        },
      },
      {
        text: 'Adresse IP',
        value: 'ip',
        filter: {
          place_holder: '', type: 'text',
        },
      },
      {
        text: 'Navigateur',
        value: 'browser',
        filter: {
          place_holder: '',
          key: 'browser',
          text: 'Type',
          type: 'select',
          multiple: true,
          values: [],
          id: 'id',
          label: 'label',
          column: 'browser',
        },
      },
      {
        text: 'Date',
        value: 'created_at',
        width: '120px',
        menu: false,
        filter: {
          place_holder: 'Date1 - Date2', type: 'date', menu: false, ref: 'menu', range: true,
        },
      }])
    const options = ref({
      itemsPerPage: 15,
      rowsPerPage: 15,
      page: 1,
      sortDesc: [true],
    })
    const page = ref(1)
    const pageCount = ref(0)
    const userTotalLocal = ref([])
    const selectedRows = ref([])

    if (router.currentRoute.params.id || props.user) {
      filters.value = filters.value.filter(ele => ele.value !== 'user.name')
      userId.value = router.currentRoute.params.id || store.state.app.user.id
    } else {
      // userId.value = store.state.app.user.id
    }

    axios
      .get('/activities/settings', {
        params: {
        },
      })
      .then(response => {
        if(response.data && response.data.types) {
          const indexH = filters.value.findIndex(ele => ele.value === 'actionable_type')
          if (indexH > 0) {
            filters.value[indexH].filter.values = response.data.types.map((ele) => {
              return {
                id: ele.actionable_type, label: ele.actionable_type,
              }})
          }
        }
        if(response.data && response.data.browsers) {
          const indexH = filters.value.findIndex(ele => ele.value === 'browser')
          if (indexH > 0) {
            filters.value[indexH].filter.values = response.data.browsers.map((ele) => {
              return {
                id: ele.browser, label: ele.browser,
              }})
          }
        }
      })
    const fetchData = () => {
      axios.get('/activities', {
        params: {
          filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({ ...ele.filter, key: ele.value, values: null })),

          term: searchQuery.value,
          userId: userId.value,
          orderBy: options.value.sortBy ? options.value.sortBy[0] : 'id',
          // eslint-disable-next-line no-nested-ternary
          direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
          page: options.value.page,
          per_page: options.value.itemsPerPage,
        },
      }).then(response => {
        items.value = response.data.data
        totalItems.value = response.data.total
      }).then(() => {
        loading.value = false
      })
    }
    // eslint-disable-next-line no-unused-vars
    let timeout = null
    watch([filters], () => {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
        loading.value = true
        selectedRows.value = []
        fetchData()
      }, 500)
    }, { deep: true })
    watch([searchQuery, options], () => {
      loading.value = true
      selectedRows.value = []
      fetchData()
    }, { deep: true })
    fetchData()

    return {
      activities,
      items,
      searchQuery,
      roleFilter,
      supervisorFilter,
      statusFilter,
      totalItems,
      loading,
      options,
      filters,
      userTotalLocal,
      selectedRows,
      isFormActive,
      page,
      pageCount,
      snackBarMessage,
      isSnackbarBottomVisible,
      loadingActivities,
      icons: {
        mdiDotsVertical,
      },

      formatDate,
      formatDateToMonthShort,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
